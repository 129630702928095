import React from "react";
import SVGAtom from "../SVGAtom/SVGAtom";
import Link from "next/link";

type Props = {
  isLinkOff?: boolean;
};

const LogoAtom: React.FC<Props> = (props) => {
  return (
    <>
      {props.isLinkOff ? (
        <div className="flex h-[20px] w-[167px] flex-shrink-0 items-center justify-center gap-[5px] pr-[10px]">
          <SVGAtom iconName="logo" width={24} height={17.27} />
          <p className="font-airbnb text-[22px] font-black leading-[20px] text-[#092B00]">
            Fandomdao
          </p>
        </div>
      ) : (
        <Link
          className="flex h-[20px] w-[167px] flex-shrink-0 items-center justify-center gap-[5px] pr-[10px]"
          href={"/home"}
          scroll={false}
        >
          <SVGAtom iconName="logo" width={24} height={17.27} />
          <p className="font-airbnb text-[22px] font-black leading-[20px] text-[#092B00]">
            Fandomdao
          </p>
        </Link>
      )}
    </>
  );
};

export default React.memo(LogoAtom);
